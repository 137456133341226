<template>
    <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
    >
        <el-menu-item index="all">全部</el-menu-item>
        <el-menu-item index="gzh">公众号</el-menu-item>
        <el-menu-item index="dev">开发</el-menu-item>
        <el-menu-item index="query">查询</el-menu-item>
        <el-menu-item index="life">生活</el-menu-item>
        <el-menu-item index="other">其它</el-menu-item>
    </el-menu>
</template>

<script>
    export default {
        name: "WyMenu",
        data(){
            return {

            }
        },
        props: {
            activeIndex: {
                type: String,
                default: 'all',
            },
        },
        methods:{
            handleSelect(index){
                if(index==='all'){
                    this.$router.replace({
                        path: '/'
                    })
                }else{
                    this.$router.replace({
                        path: '/c/'+index
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>