<template>
    <div class="wy-footer">
        <div class="wy-footer-content">
            <div class="wy-footer-content-link">

            </div>
            <div class="wy-footer-content-link">
                <a href="https://openai.xguan.com.cn/" target="_blank">chatGPT</a>
                <a href="https://www.xguan.com.cn/" target="_blank">微文库</a>
            </div>
            <div class="wy-footer-content-webinfo">
                <a href="/">Leaf Tools || 叶子工具</a>
                <a href="">关于我们</a>
                <a href="">联系我们</a>
                <a href="">使用帮助</a>
                <a href="https://beian.miit.gov.cn/" target="_blank">陕ICP备19021661号</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WyFooter",
        data() {
            return {

            };
        }
    }
</script>

<style lang="scss" scoped>
    .wy-footer{
        min-height: 60px;
        padding:  15px 0;
    }
    .wy-footer-content{
        width: 1180px;
        margin: 0 auto;
        min-height: 60px;
    }
    .wy-footer-content-webinfo>a,.wy-footer-content-link>a{
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        font-size: 12px;
        line-height: 22px;
        color: #495770;
        white-space: nowrap;
        margin-right: 5px;
        text-decoration: none;
    }
    .wy-footer-content-webinfo>a:hover,.wy-footer-content-link>a:hover{
        color: #007aff;
    }
    .wy-footer-content-webinfo{
        width: 60%;
    }
</style>