import { createStore } from 'vuex'

export default createStore({
  state: {
    token:'',
    userInfo:{}
  },
  getters: {
  },
  mutations: {
    // 从 localStorage 中恢复 state
    restoreState(state) {
      const token = localStorage.getItem('token');
      const userInfoString = localStorage.getItem('userInfo');
      const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

      if (token && userInfo) {
        state.token = token;
        state.userInfo = userInfo;
      }
    },
    // 登录
    login(state, res) {
      res.userInfo = res.userinfo;

      localStorage.setItem('token', res.userInfo.token);
      localStorage.setItem('userInfo', JSON.stringify(res.userInfo));
      state.token = res.userInfo.token;
      state.userInfo = res.userInfo;
    },
    // 退出登录
    logout(state) {
      state.token = '';
      state.userInfo = {};
      localStorage.clear();
    }
  },
  actions: {
  },
  modules: {
  }
})
