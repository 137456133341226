<template>
    <view class="wy-head-user">
        <el-row>

            <el-col :span="12">
                <el-row>
                    <view class="logo-view">
                        <a href="/">
                            <img src="../assets/image/logo.jpg" class="logo" alt="" >
                        </a>
                    </view>
                    <view class="web_name">
                        <router-link to="/">{{web_name}}</router-link>
                    </view>
                </el-row>
            </el-col>

            <el-col :span="12">
                <div class="user-right" v-if="userInfo.id">
                    <div class="user-right-b">
                        <router-link to="/">
                            <el-tooltip
                                    class="box-item"
                                    effect="dark"
                                    content="帮助"
                                    placement="bottom"
                            >
                                <el-icon :size="20" color="#909399"><QuestionFilled /></el-icon>
                            </el-tooltip>
                        </router-link>
                        <router-link to="/">
                            <el-tooltip
                                    class="box-item"
                                    effect="dark"
                                    content="通知"
                                    placement="bottom"
                            >
                                <el-icon :size="20" color="#909399"><Message /></el-icon>
                            </el-tooltip>
                        </router-link>
                    </div>
                    <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                      <el-avatar :size="35" :src="userInfo.avatar" />
                    </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item command="a" icon="Plus">个人中心</el-dropdown-item>
                                <el-dropdown-item command="a">
                                    Action 2
                                </el-dropdown-item>
                                <el-dropdown-item command="a">Action 3</el-dropdown-item>
                                <el-dropdown-item command="a">Action 4</el-dropdown-item>
                                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>

                <div class="user-right" v-if="!userInfo.id">
                    <div class="user-right-c">
                        <router-link to="/login">
                            登录/注册
                        </router-link>
                    </div>
                </div>

            </el-col>
        </el-row>
    </view>
</template>

<script>

    import { logout } from '@/request/api';// 导入我们的api接口
    import router from '@/router'
    export default {
        name:"WyHeadUser",
        data() {
            return {
                web_name:'Leaf Tools',
                userInfo:this.$store.state.userInfo
            };
        },
        props:{
            is_login:{
                type: Boolean,
                default: false,
            },
        },
        methods:{
            toIndex(){
                router.replace({
                    path: '/'
                });
            },
            handleCommand(command) {
                if(command==='logout'){
                    //退出登录
                    logout({}).then(res=>{
                        this.userInfo = {}
                        this.$store.commit('logout');
                        this.$message({
                            message: '退出成功',
                            type: 'success'
                        });
                        router.replace({
                            path: '/'
                        });
                    }).catch(err=>{
                        this.userInfo = {}
                        this.$store.commit('logout');
                        this.$message({
                            message: '退出成功',
                            type: 'success'
                        });
                        router.replace({
                            path: '/'
                        });
                        console.log(err)
                    })
                }
                if(command==='info'){
                    console.log('info')
                }
                if(command==='set'){
                    console.log('set')
                }
            },
            tooltipClick(e){
                if(e === 'tz'){
                    console.log('tz')
                }
                if(e === 'bz'){
                    console.log('bz')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .user-right{
        text-align: right;
    }
    .el-dropdown-link:focus {
        outline: none;
    }
    .web_name{
        line-height: 60px;
        display: inline-block;
        vertical-align:top;
        cursor: pointer;
    }
    .web_name>a{
        color: #2c3e50;
        text-decoration: none;
    }
    .logo{
        display: block;
    }
    .logo-view{
        display: inline-block;
        margin-right: 20px;
        cursor: pointer;
        line-height: 60px;
    }
    .logo-view>a{
        display: inline-block;
        margin-top: 10px;
    }
    .logo-view>a>img{
        height: 30px;
        min-width: 70px;
    }
    .wy-head-user{
        min-height: 60px;
        width: 95%;
        margin: 0 auto;
        padding: 10px 0;
    }
    .user-right-b,.user-right-c{
        display: inline-block;
        line-height: 60px;
    }
    .user-right-b>a,.user-right-c>a{
        display: inline-block;
        margin-right: 15px;
    }
    .user-right-c>a{
        text-decoration: none;
        font-size: 14px;
        color: #2c3e50;
    }
    .user-right-c>a:hover{
        color: #409EFF;
    }
    .el-dropdown{
        margin-top: 10px;
    }

</style>