<template>
    <div class="home">
        <el-container>
            <el-header>
                <WyHeadUser :is_login="false"></WyHeadUser>
            </el-header>
            <el-main>
                <div class="main-content">
                    <WyMenu activeIndex="all"></WyMenu>
                    <div class="content">
                        <WyColumn :columnList="columnList"></WyColumn>
                    </div>
                </div>

            </el-main>
            <el-footer>
                <WyFooter></WyFooter>
            </el-footer>
        </el-container>
    </div>
</template>
<style type="scss" scoped>
    .home {
        height: 100%;
    }

    .el-container {
        min-height: calc(100% - 30px);
        min-width: 1180px;
    }

    .el-header {
        min-height: 60px;
        background: #f8f9fa;
    }

    .el-footer {
        min-height: 60px;
    }

    .el-main {
        min-height: calc(100% - 120px);
        overflow: hidden;
    }

    .main-content {
        width: 1180px;
        margin: 0 auto;
        text-align: center;
    }

    .content {
        margin-top: 20px;
    }

</style>

<script>
    import WyHeadUser from '@/components/WyHeadUser.vue'; // @ is an alias to /src
    import WyFooter from '@/components/WyFooter.vue';// @ is an alias to /src
    import {testPost} from '@/request/api';// 导入我们的api接口
    import WyMenu from '@/components/WyMenu.vue';// @ is an alias to /src
    import WyColumn from '@/components/WyColumn.vue';// @ is an alias to /src
    export default {
        data() {
            return {
                title: '首页',
                columnList: [
                    {
                        column_url: '/gzh/group',
                        column_img: 'https://img.xguan.com.cn/img/logo-mp.png',
                        name: '公众号文章生产',
                        class_name: '[公众号]',
                        desc: '公众号文章自动生产以及发布,文章管理',
                        class_identification: 'gzh'
                    },
                    {
                        column_url: '/timestamp',
                        column_img: 'https://img.xguan.com.cn/img/timestamp-logo.png',
                        name: 'Unix时间戳',
                        class_name: '[开发]',
                        desc: '在线时间戳转换 ',
                        class_identification: 'dev'
                    },
                    {
                        column_url: '/imagetobase',
                        column_img: 'https://img.xguan.com.cn/img/base64.png',
                        name: '图片转base64',
                        class_name: '[开发]',
                        desc: '图片数据转换成base64',
                        class_identification: 'dev'
                    },
                    {
                        column_url: '/basetoimage',
                        column_img: 'https://img.xguan.com.cn/img/image.png',
                        name: 'base64转图片',
                        class_name: '[开发]',
                        desc: 'base64数据转换成图片',
                        class_identification: 'dev'
                    },
                    {
                        column_url: '/sfn_verif',
                        column_img: 'https://img.xguan.com.cn/img/sfn.png',
                        name: '身份证姓名校验',
                        class_name: '[查询]',
                        desc: '校验身份证号码和姓名是否一致',
                        class_identification: 'query'
                    },
                    {
                        column_url: '/sf_verif',
                        column_img: 'https://img.xguan.com.cn/img/sf.png',
                        name: '身份证规则校验',
                        class_name: '[查询]',
                        desc: '校验身份证号码是否是有效的身份证号码',
                        class_identification: 'query'
                    },
                    {
                        column_url: '/phonedic',
                        column_img: 'https://img.xguan.com.cn/img/mobile.png',
                        name: '常用电话大全',
                        class_name: '[生活]',
                        desc: '常用电话大全',
                        class_identification: 'life'
                    },
                    {
                        column_url: '/phonedic',
                        column_img: 'https://img.xguan.com.cn/img/zjz.png',
                        name: '常用电话大全',
                        class_name: '[其它]',
                        desc: '将上传的普通照片，去除背景，裁剪等。',
                        class_identification: 'other'
                    }
                ]
            }
        },
        methods: {
            https() {
                console.log(123)
                testPost({type: 0, page: 1}).then(res => {
                    console.log(res)
                }).catch(res => {
                    console.log(res)
                })
            }
        },
        mounted() {
            this.https()
            console.log(this.$store.state, '--')
        },
        components: {
            WyHeadUser,
            WyFooter,
            WyMenu,
            WyColumn
        },
    }
</script>
