import { get, post } from './http'
export const emailLogin = p => post('user/emaillogin', p);
export const emsSend = p => post('Ems/send', p);
export const logout = p => post('user/logout', p);

//公众号
export const getTableData = p => post('wechat/gzh/getList', p);
export const gzhAdd = p => post('wechat/gzh/add', p);
export const gzhEdit = p => post('wechat/gzh/edit', p);
export const gzhDel = p => post('wechat/gzh/del', p);
export const gzhEditStatus = p => post('wechat/gzh/editStatus', p);

export const gzhGroupSave = p => post('wechat/group/gzhGroupSave', p);
export const getGroupList = p => post('wechat/group/getGroupList', p);
export const gzhGroupDel = p => post('wechat/group/del', p);
export const gzhGroupContentSave = p => post('wechat/group/gzhGroupContentSave', p);
export const getGroupContentList = p => post('wechat/group/getGroupContentList', p);
export const gzhGroupContentQiyong = p => post('wechat/group/gzhGroupContentQiyong', p);
export const gzhGroupContentDel = p => post('wechat/group/gzhGroupContentDel', p);

export const getgzhConfig = p => post('wechat/config/getConfig', p);
export const gzhConfigSave = p => post('wechat/config/gzhConfigSave', p);

export const getDraftList = p => post('wechat/Content/getDraftList', p);
export const getPublishList = p => post('wechat/Content/getPublishList', p);
export const fbGzhContent = p => post('wechat/Content/fbGzhContent', p);

export const testPost = p => get('demo/test1', p);


