import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        meta: {
            title: '叶子工具-Leaf Tools'
        },
        component: HomeView
    },
    {
        path: '/c/gzh',
        name: 'cgzh',
        meta: {
            title: '公众号工具'
        },
        component: () => import(/* webpackChunkName: "cgzh" */ '../views/c/GzhView.vue')
    },
    {
        path: '/gzh/:gzh_group_id',
        name: 'gzh',
        meta: {
            title: '公众号文章生产'
        },
        component: () => import(/* webpackChunkName: "gzh" */ '../views/gzh/IndexView.vue')
    },
    {
        path: '/gzh/group',
        name: 'gzhgroup',
        meta: {
            title: '公众号文章生产'
        },
        component: () => import(/* webpackChunkName: "gzhgroup" */ '../views/gzh/GroupView.vue')
    },
    {
        path: '/gzh/contentlist',
        name: 'contentlist',
        meta: {
            title: '公众号分组内容列表'
        },
        component: () => import(/* webpackChunkName: "gzhgroup" */ '../views/gzh/ContentListView.vue')
    },
    {
        path: '/gzh/details/:gzh_id',
        name: 'gzhdetails',
        meta: {
            title: '公众号详情'
        },
        component: () => import(/* webpackChunkName: "gzhdetails" */ '../views/gzh/DetailsView.vue')
    },
    {
        path: '/c/query',
        name: 'cquery',
        meta: {
            title: '查询工具'
        },
        component: () => import(/* webpackChunkName: "cgzh" */ '../views/c/QueryView.vue')
    },
    {
        path: '/sf_verif',
        name: 'sf_verif',
        meta: {
            title: '身份证姓名校验'
        },
        component: () => import(/* webpackChunkName: "sf_verif" */ '../views/query/SfView.vue')
    },
    {
        path: '/sfn_verif',
        name: 'sfn_verif',
        meta: {
            title: '身份证规则校验'
        },
        component: () => import(/* webpackChunkName: "sfn_verif" */ '../views/query/SfnView.vue')
    },
    {
        path: '/c/life',
        name: 'clife',
        meta: {
            title: '生活工具'
        },
        component: () => import(/* webpackChunkName: "cgzh" */ '../views/c/LifeView.vue')
    },
    {
        path: '/phonedic',
        name: 'phonedic',
        meta: {
            title: '常用电话大全'
        },
        component: () => import(/* webpackChunkName: "phonedic" */ '../views/life/PhonedicView.vue')
    },
    {
        path: '/c/other',
        name: 'cother',
        meta: {
            title: '其他工具'
        },
        component: () => import(/* webpackChunkName: "cgzh" */ '../views/c/OtherView.vue')
    },
    {
        path: '/c/dev',
        name: 'cdev',
        meta: {
            title: '开发工具'
        },
        component: () => import(/* webpackChunkName: "cdev" */ '../views/c/DevView.vue')
    },
    {
        path: '/timestamp',
        name: 'timestamp',
        meta: {
            title: '时间戳转换'
        },
        component: () => import(/* webpackChunkName: "timestamp" */ '../views/dev/TimestampView.vue')
    },
    {
        path: '/basetoimage',
        name: 'basetoimage',
        meta: {
            title: 'base转图片'
        },
        component: () => import(/* webpackChunkName: "basetoimage" */ '../views/dev/BasetoimageView.vue')
    },
    {
        path: '/imagetobase',
        name: 'imagetobase',
        meta: {
            title: '图片转base64'
        },
        component: () => import(/* webpackChunkName: "imagetobase" */ '../views/dev/ImagetobaseView.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录'
        },
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/404',
        name: 'NotFound',
        meta: {
            title: '404'
        },
        component: () => import('@/views/NotFound.vue'),
    },
    // 最后添加一个通配符路由，用于匹配所有未知路由
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404',
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
