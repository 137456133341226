<template>
    <div>
        <el-row  :gutter="20" class="column-el-row">
            <el-col :span="6" v-for="(item,index)  in columnList" :key="index">
                <div class="index-item-content">
                    <div class="index-item-content-top">
                        <router-link :to="item.column_url" class="index-item-content-top-image">
                            <el-image style="width: 50px; height: 50px" :src="item.column_img" fit="fit"  />
                        </router-link>
                        <router-link :to="item.column_url" class="index-item-content-top-title">{{item.name}}</router-link>
                        <router-link :to="('/c/'+item.class_identification)" class="index-item-content-top-class"><span class="index-item-content-top-class-span">{{item.class_name}}</span></router-link>
                    </div>
                    <el-divider />
                    <div class="index-item-content-bottom">
                        <div class="index-item-content-bottom-title">
                            <router-link :to="item.column_url" class="index-item-content-bottom-title-a" >{{item.desc}}</router-link>
                        </div>
                        <div class="index-item-content-bottom-link">
                            <el-row>
                                <el-col :span="20" class="index-item-content-bottom-link-col">
                                    <router-link :to="item.column_url" class="index-item-content-bottom-link-a">https://leaftools.cn{{item.column_url}}</router-link>
                                </el-col>
                                <el-col :span="4">
                                    <div class="index-item-content-bottom-link-b-view">
                                        <div class="index-item-content-bottom-link-b-view-a">
                                            <router-link :to="item.column_url" class="index-item-content-bottom-link-b">进入</router-link>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "WyColumn",
        data(){
            return {

            }
        },
        props:{
            columnList:{
                type:Array,
                default:()=>[]
            },
            activeIndex:{
                type:String,
                default:''
            }
        }
    }
</script>

<style scoped>
    .index-item-content{
        min-height: 117px;
        padding: 8px;
        background: #fdfdfd;
        border-radius: 8px;
        border: 1px solid #dee2e6 !important;
    }
    .index-item-content:hover{
        box-shadow: 0 .3rem .55rem rgba(0,0,0,.2);
    }
    .el-divider{
        margin: 2px 0;
    }
    .index-item-content-top-image{
        margin-right: 10px;
    }
    .index-item-content-top{
        display: flex;
        justify-content: space-between;
    }
    .index-item-content-top>a{
        display: inline-block;
    }
    .index-item-content-top-title{
        text-decoration: none;
        color: #333;
        font-size: 1rem;
        vertical-align:top;

    }
    .index-item-content-top-class{
        text-decoration: none;
        color: #333;
        font-size: .875rem;
        margin-left:auto;
        position: relative;
    }
    .index-item-content-top-class-span{
        display: inline-block;
        line-height: 20px;
        margin-top: 30px;
    }
    .index-item-content-bottom-title,.index-item-content-bottom-title-a{
        color: #212529;
        text-align: left;
        text-overflow: ellipsis;
        padding-top: .5rem !important;
        padding-bottom: .25rem !important;
        font-size: .875rem;
        text-decoration: none;
    }
    .index-item-content-bottom-link{
        margin-top: 5px;
    }
    .index-item-content-bottom-link-a{
        color: #999;
        font-size: .875rem;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

    }
    .index-item-content-bottom-link-b-view{
        text-align: right;
    }
    .index-item-content-bottom-link-b{
        font-size: .875rem;
        color: #409EFF;
        text-decoration: none;
        padding: 2px 6px;
        display: inline-block;
    }
    .index-item-content-bottom-link-b:hover::before {
        left: 0;
        right: 0;
        border-bottom-color: #409EFF;
    }
    .index-item-content-bottom-link-b-view-a{
        position: relative;
        display: inline-block;
    }
    .index-item-content-bottom-link-b::before {
        content: "";
        position: absolute;
        transition: all .2s ease-in-out;
        left: 50%;
        right: 50%;
        bottom: 0;
        border-bottom: 2px solid transparent;
        border-bottom-color: transparent;
    }
    .column-el-row>.el-col{
        margin-bottom: 20px;
    }
    .index-item-content-bottom-link-col{
        text-align: left;
    }
</style>